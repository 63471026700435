<template>
  <div class="property-filters">
    <div v-if="propertyFilters" class="fd-p-4 fd-pb-32">
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="phases" class="fd-mb-2">Phases</label>
        <multiselect
          v-if="type === 'project'"
          id="phases"
          select-label=""
          deselect-label=""
          label="name"
          track-by="id"
          v-model="filters.phase"
          @input="selectedPhase"
          :options="propertyFilters.phase"
        />
        <b-form-input
          v-else
          v-model="filters.phase"
          placeholder="20"
          :disabled="true"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="complex" class="fd-mb-2">Complex</label>
        <multiselect
          v-if="type === 'project' || type === 'phase'"
          id="complex"
          v-model="filters.complex"
          label="name"
          select-label=""
          deselect-label=""
          :options="propertyFilters.complex"
          @input="selectedComplex"
          track-by="phase_id"
        />
        <b-form-input
          v-else
          v-model="filters.complex"
          placeholder="20"
          :disabled="true"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="template" class="fd-mb-2">Template</label>
        <multiselect
          id="template"
          select-label=""
          deselect-label=""
          label="name"
          track-by="id"
          v-model="filters.template"
          :options="propertyFilters.template"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="floor" class="fd-mb-2">Floor</label>
        <multiselect
          id="floor"
          v-model="filters.floor"
          select-label=""
          deselect-label=""
          :options="propertyFilters.floor"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="unit_num" class="fd-mb-2">Unit Number</label>
        <multiselect
          id="unit_num"
          v-model="filters.unit_num"
          select-label=""
          deselect-label=""
          :options="propertyFilters.unit_num"
        />
      </div>

      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="visibility" class="fd-mb-2">Visibility</label>
        <multiselect
          id="visibility"
          v-model="filters.visibility"
          :options="propertyFilters.visibility"
          select-label=""
          deselect-label=""
        />
      </div>
      <span class="fd-block fd-w-full fd-my-4" style="border: 1px solid #D8D6DE"></span>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="payment" class="fd-mb-2">Payment</label>
        <multiselect
          id="payment"
          v-model="filters.payment"
          select-label=""
          deselect-label=""
          :options="propertyFilters.payment"
        />
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label class="fd-mb-2">Price</label>
        <vue-slider
          class=""
          v-model="filters.price"
          :process-style="{ backgroundColor: '#284A33', height: '5px' }"
          v-bind="sliderOptions"
          :dotStyle="{ width: '18px', height: '18px' }"
          :tooltip="'none'"
          :use-keyboard="false"
          :lazy="true"
        >
          <template v-slot:dot="{ value }">
            <div :class="['custom-dot']"></div>
          </template>
        </vue-slider>
        <div class="fd-mt-2">
          <span class="fd-text-xs" style="color: #626687">{{ `$ ${filters.price[0]} - $ ${filters.price[1]}` }}</span>
        </div>
      </div>
      <span class="fd-block fd-w-full fd-my-4" style="border: 1px solid #D8D6DE"></span>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="status" class="fd-mb-2">Status</label>
        <multiselect
          id="status"
          v-model="filters.status"
          select-label=""
          deselect-label=""
          :options="['Sold', 'Reserved', 'Available']"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import 'vue-search-select/dist/VueSearchSelect.css'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import {mapGetters} from "vuex";

export default {
  name: "PropertyFilter",
  components: {BFormInput, Multiselect, VueSlider},
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filters: {
        id: '',
        phase: null,
        complex: null,
        template: null,
        type: null,
        floor: null,
        rooms: null,
        unit_num: null,
        net: null,
        gross: null,
        visibility: null,
        payment: null,
        status: null,
        price: [100000, 400000]
      },
      sliderOptions: {
        minRange: 1,
        min: 1,
        max: 500000,
        interval: 1
      },
    }
  },
  created() {
    this.$store.dispatch('project/getPropertyFilters', {
      id: this.$route.params.id,
      type: this.type
    })
  },
  watch: {
    filters: {
      handler(newVal) {
        let filters = {}
        let newFilters = JSON.parse(JSON.stringify(this.filters))
        newFilters.min_price = newFilters.price[0]
        newFilters.max_price = newFilters.price[1]
        newFilters.template_property = newFilters.template
        delete newFilters.price
        delete newFilters.template

        Object.keys(newFilters).forEach(key => {
          if (Array.isArray(newFilters[key])) {
            if (typeof newFilters[key][0] === 'object') {
              filters[key] = newFilters[key].map(item => item.id)
              return;
            }
            filters[key] = newFilters[key].map(item => item)
            return;
          }
          if (typeof newFilters[key] === 'object' && newFilters[key]) {
            filters[key] = newFilters[key].id
            return;
          }
          if (newFilters[key]) {
            filters[key] = newFilters[key]
          }
        })
        return this.$emit('filters', filters)
      },
      deep: true
    }
  },
  computed: {
    propertyFilters() {
      const filters = this.$store.getters["project/propertyFilters"]
      if (Array.isArray(filters)) return false
      this.filters.price = [filters.price.min, filters.price.max]
      this.sliderOptions.min = filters.price.min
      this.sliderOptions.max = filters.price.max
      return filters
    }
  },
  methods: {
    selectedPhase(val, id) {
      if (!val) {
        this.filters.complex = ''
        return;
      }
      this.propertyFilters.complex.forEach(item => {
        if (item.phase_id === val.id) {
          this.filters.complex = item
        }
      })
    },
    selectedComplex(val, id) {
      if (this.type !== 'project') return;
      if (!val) {
        this.filters.phase = ''
        return;
      }
      this.propertyFilters.phase.forEach(item => {
        if (item.id === val.id) {
          this.filters.phase = item
        }
      })
    },
    applyFilter() {

    },
    clearAllFilters() {

    }
  }
}
</script>

<style lang="scss" scoped>
.custom-dot {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #E1CDAE;
  transition: all .3s;
  position: absolute;
  top: -2px;
  cursor: pointer;
  z-index: 0 !important;

  &:after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.25);
  }

  &:hover {
    transform: rotateZ(45deg);
  }
}
::v-deep .vue-slider-rail {
  background-color: #EDEDED !important;
}
.property-filters {
  & ::v-deep .multiselect {
    & .multiselect__select {
      margin-top: 0;
    }
    & .multiselect__tags {
      padding-top: 3px !important;
      padding-bottom: 2px;
      border: 1px solid #D8D6DE !important;
      border-radius: 6px !important;
    }
    & .multiselect__placeholder {
      padding-top: 6px;
      margin-bottom: 5px;
      margin-left: 10px;
      font-size: 12px;
    }
    & .multiselect__content-wrapper {
      z-index: 20 !important;
    }
  }
}
</style>