<template>
  <div v-if="propertyFilters" class="property-add">
    <div class="fd-relative fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
      <div class="fd-flex fd-items-center">
        <span class="fd-block fd-ml-2">{{ getName }}</span>
      </div>
      <div @click="closeAddPropertySidebar" class="fd-cursor-pointer">
        <SvgLoader :name="'close'" />
      </div>
    </div>

    <div class="fd-px-4 fd-pb-3">
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-my-3">
        <label for="phases" class="fd-mb-2">Phases</label>
        <multiselect
          v-if="type === 'project'"
          id="phases"
          :disabled="readOnly"
          select-label=""
          deselect-label=""
          label="name"
          track-by="id"
          v-model="form.phase"
          @input="selectedPhase"
          :options="propertyFilters.phase"
        />
        <b-form-input
          v-else
          v-model="form.phase"
          :disabled="true"
        />

        <span v-if="errors.phase" class="fd-block fd-text-sm fd-text-red-500 fd-font-normal">{{ errors.phase }}</span>
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="complex" class="fd-mb-2">Complex</label>
        <multiselect
          v-if="type === 'project' || type === 'phase'"
          id="complex"
          :disabled="readOnly"
          select-label=""
          deselect-label=""
          label="name"
          track-by="id"
          v-model="form.complex"
          @input="selectedComplex"
          :options="propertyFilters.complex"
        />
        <b-form-input
          v-else
          v-model="form.complex"
          :disabled="true"
        />

        <span v-if="errors.complex" class="fd-block fd-text-sm fd-text-red-500 fd-font-normal">{{ errors.complex }}</span>
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="template" class="fd-mb-2">Template</label>
        <multiselect
          id="template"
          :disabled="readOnly"
          select-label=""
          deselect-label=""
          label="name"
          track-by="id"
          v-model="form.template"
          :options="propertyFilters.template"
        />
        <span v-if="errors.template" class="fd-block fd-text-sm fd-text-red-500 fd-font-normal">{{ errors.template }}</span>
      </div>
      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="floor" class="fd-mb-2">Floor</label>
        <b-form-input id="floor" type="number" v-model="form.floor" placeholder="20" :disabled="readOnly" />
        <span v-if="errors.floor" class="fd-block fd-text-sm fd-text-red-500 fd-font-normal">{{ errors.floor }}</span>
      </div>

      <div v-if="readOnly" class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="rooms" class="fd-mb-2">Rooms</label>
        <b-form-input id="rooms" type="number" v-model="form.rooms" placeholder="" :disabled="readOnly" />
      </div>

      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="unit_num" class="fd-mb-2">Unit Number</label>
        <b-form-input type="number" id="unit_num" v-model="form.unit_number" placeholder="20" :disabled="readOnly" />
        <span v-if="errors.unit_number" class="fd-block fd-text-sm fd-text-red-500 fd-font-normal">{{ errors.unit_number }}</span>
      </div>

      <div v-if="readOnly" class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="net" class="fd-mb-2">Net</label>
        <b-form-input id="net" type="number" v-model="form.net" placeholder="" :disabled="readOnly" />
      </div>
      <div v-if="readOnly" class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="gross" class="fd-mb-2">Gross</label>
        <b-form-input id="gross" type="number" v-model="form.gross" placeholder="" :disabled="readOnly" />
      </div>

      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="visibility" class="fd-mb-2">Visibility</label>
        <multiselect
          id="visibility"
          :disabled="readOnly"
          select-label=""
          deselect-label=""
          v-model="form.visibility"
          :options="propertyFilters.visibility"
        />
        <span v-if="errors.visibility" class="fd-block fd-text-sm fd-text-red-500 fd-font-normal">{{ errors.visibility }}</span>
      </div>

      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="mainPrice" class="fd-mb-2">Main Price</label>
        <b-form-input id="mainPrice" type="number" v-model="form.main_price" placeholder="0000000" :disabled="readOnly" />
        <span v-if="errors.main_price" class="fd-block fd-text-sm fd-text-red-500 fd-font-normal">{{ errors.main_price }}</span>
      </div>

      <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-my-5 fd-py-6 fd-border-t fd-border-b">
        <div class="fd-flex fd-items-center fd-text-white fd-text-sm fd-font-medium">
          <span
            @click="isCash = true"
            class="fd-block fd-w-full fd-bg-theme-4 fd-rounded-l-lg fd-text-center fd-py-3 fd-cursor-pointer"
            :class="[isCash ? '' : 'fd-bg-opacity-70']"
          >
            <span>Cash</span>
          </span>
          <span
            @click="isCash = false"
            class="fd-block fd-w-full fd-bg-theme-4 fd-rounded-r-lg fd-text-center fd-py-3 fd-cursor-pointer"
            :class="[isCash ? 'fd-bg-opacity-70' : '']"
          >
            <span>Installment</span>
          </span>
        </div>

        <div v-if="isCash" class="fd-text-base fd-font-semibold fd-text-theme-13 fd-my-3">
          <label for="discount" class="fd-mb-2">Discount</label>
          <b-form-input type="number" id="discount" v-model="form.payment.cash.discount" placeholder="20" :disabled="readOnly" />
          <span v-if="errors.discount_percent" class="fd-block fd-text-sm fd-text-red-500 fd-font-normal">{{ errors.discount_percent }}</span>
        </div>

        <div v-if="!isCash">

          <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-my-3">
            <label for="installmentMonth" class="fd-mb-2">Installment Month</label>
            <b-form-input type="number" id="installmentMonth" v-model="form.payment.installment.month" placeholder="20" :disabled="readOnly" />
          </div>

          <div class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3 ">
            <label for="installmentDiscount" class="fd-mb-2">Installment Discount</label>
            <b-form-input type="number" id="installmentDiscount" v-model="form.payment.installment.discount" placeholder="20" :disabled="readOnly" />
          </div>
        </div>
      </div>

      <div v-if="readOnly" class="fd-text-base fd-font-semibold fd-text-theme-13 fd-mb-3">
        <label for="status" class="fd-mb-2">Status</label>
        <multiselect
          id="status"
          :disabled="readOnly"
          select-label=""
          deselect-label=""
          v-model="form.status"
          :options="['Sold', 'Reserved', 'Available']"
        />
        <span v-if="errors.status" class="fd-block fd-text-sm fd-text-red-500 fd-font-normal">{{ errors.status }}</span>
      </div>

      <div class="fd-text-base fd-text-theme-13 fd-mb-3">
        <label for="extra" class="fd-mb-2 fd-font-semibold">Extra info</label>
        <b-form-textarea id="extra" v-model="form.extra_info" rows="12" max-rows="12" :disabled="readOnly" style="font-size: 0.75rem !important; line-height: 1rem; !important;" />
      </div>
    </div>

    <div v-if="!readOnly" class="fd-w-full fd-p-4 fd-bg-white" style="z-index: 99">
      <b-button @click="create" variant="primary" class="fd-w-full fd-mb-2">Save</b-button>
    </div>
  </div>
</template>

<script>
import {BButton, BSidebar, BFormInput, BFormCheckbox, BFormTextarea} from "bootstrap-vue";
import Multiselect from 'vue-multiselect'
import 'vue-search-select/dist/VueSearchSelect.css'
import {mapGetters} from "vuex";

export default {
  name: "PropertyForm",
  components: {BButton, BSidebar, BFormInput, Multiselect, BFormCheckbox, BFormTextarea},
  props: {
    isUpdate: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    propertyId: {
      type: Number
    },
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isCash: true,
      form: {
        id: '',
        phase: null,
        complex: null,
        template: null,
        type: null,
        floor: null,
        rooms: null,
        unit_number: null,
        net: null,
        gross: null,
        visibility: null,
        status: null,
        main_price: '',
        extra_info: '',
        payment: {
          cash: {
            discount: ''
          },
          installment: {
            discount: '',
            month: null
          }
        }
      },
      errors: {
        phase: '',
        complex: '',
        template: '',
        floor: '',
        unit_number: '',
        main_price: '',
        discount_percent: '',
        visibility: '',
        status: ''
      }
    }
  },
  async created() {
    await this.$store.dispatch('project/getPropertyFilters', {
      id: this.$route.params.id,
      type: this.type
    })
    if (this.isUpdate || this.readOnly) {
      await this.$store.dispatch('project/getProperty', {
        id: this.$route.params.id,
        propertyId: this.propertyId
      }).then(response => {
        this.dataPreparation(response)
      })
    }
  },
  computed: {
    ...mapGetters({
      propertyDetails: 'project/propertyDetails'
    }),
    propertyFilters() {
      const filters = this.$store.getters["project/propertyFilters"]
      // this.price = [filters.price.min, filters.price.max]
      // this.sliderOptions.min = filters.price.min
      // this.sliderOptions.max = filters.price.max
      if (Array.isArray(filters)) return false
      if (this.type !== 'project' && !this.isUpdate && !this.readOnly) {
        this.form.phase = filters.phase.name
      }
      if (this.type === 'complex' && !this.isUpdate && !this.readOnly) {
        this.form.complex = filters.complex.name
      }
      return filters
    },
    getName() {
      if (this.isUpdate) return 'Edit Property'
      if (this.readOnly) return 'View Property'
      return 'Add Property'
    }
  },
  methods: {
    selectedPhase(val, id) {
      if (!val) {
        this.form.complex = ''
        return;
      }
      this.propertyFilters.complex.forEach(item => {
        if (item.phase_id === val.id) {
          this.form.complex = item
        }
      })
    },
    selectedComplex(val, id) {
      if (this.type !== 'project') return;
      if (!val) {
        this.form.phase = ''
        return;
      }
      this.propertyFilters.phase.forEach(item => {
        if (item.id === val.id) {
          this.form.phase = item
        }
      })
    },
    async create() {
      this.clearErrors()

      if (this.formValidated()) {
        let actionType = this.isUpdate ? 'updateProperty' : 'createNewProperty'
        let form = JSON.parse(JSON.stringify(this.form))

        if (this.type !== 'project') {
          form.phase = { id: this.propertyFilters.phase.id }
        }
        if (this.type === 'complex') {
          form.complex = { id: this.propertyFilters.complex.id }
        }
        await this.$emit('setForm', form)
        return await this.$emit('saveData', actionType)
      }
    },
    closeAddPropertySidebar() {
      this.$root.$emit('bv::toggle::collapse', 'add-property-sidebar')
    },
    dataPreparation(response) {
      if (this.type === 'project') {
        this.propertyFilters.phase.forEach(item => {
          if (item.id === response.phase) {
            this.form.phase = item
          }
        })
      } else {
        this.form.phase = this.propertyFilters.phase.name
      }

      if (this.type !== 'complex') {
        this.propertyFilters.complex.forEach(item => {
          if (item.id === response.complex) {
            this.form.complex = item
          }
        })
      } else {
        this.form.complex = this.propertyFilters.complex.name
      }

      this.propertyFilters.template.forEach(item => {
        if (item.id === response.template) {
          this.form.template = item
        }
      })
      this.form.floor = response.floor
      this.form.visibility = response.visibility
      this.form.main_price = response.price
      this.form.payment.cash.discount = response.discount_percent
      this.form.rooms = response.rooms
      this.form.status = response.status
      this.form.gross = response.gross
      this.form.net = response.net
      this.form.extra_info = response.extra_info
      this.form.unit_number = response.unit_number
      this.form.payment.installment.discount = response.installment_discount_percent
      this.form.payment.installment.month = response.installment_month_count
    },
    formValidated() {
      let validated = true

      if (!this.form.phase && this.type === 'project') {
        this.errors.phase = this.$t('errors.theFieldIsRequired', { key: this.$t('errors.phase') })
        validated = false
      }
      if (!this.form.complex && this.type !== 'complex') {
        this.errors.complex = this.$t('errors.theFieldIsRequired', { key: this.$t('errors.complex') })
        validated = false
      }
      if (!this.form.template) {
        this.errors.template = this.$t('errors.theFieldIsRequired', { key: this.$t('errors.template') })
        validated = false
      }
      if (!this.form.floor) {
        this.errors.floor = this.$t('errors.theFieldIsRequired', { key: this.$t('errors.floor') })
        validated = false
      }
      if (!this.form.unit_number) {
        this.errors.unit_number = this.$t('errors.theFieldIsRequired', { key: this.$t('errors.unit_number') })
        validated = false
      }
      if (!this.form.main_price) {
        this.errors.main_price = this.$t('errors.theFieldIsRequired', { key: this.$t('errors.main_price') })
        validated = false
      }
      if (this.form.payment.cash.discount < 0 || !this.form.payment.cash.discount) {
        this.errors.discount_percent = this.$t('errors.theFieldIsRequired', { key: this.$t('errors.discount_percent') })
        validated = false
      }
      if (!this.form.visibility) {
        this.errors.visibility = this.$t('errors.theFieldIsRequired', { key: this.$t('errors.visibility') })
        validated = false
      }

      return validated
    },
    clearErrors() {
      this.errors = {
        phase: '',
        complex: '',
        template: '',
        floor: '',
        unit_number: '',
        main_price: '',
        discount_percent: '',
        visibility: '',
        status: ''
      }
    }
  },
  beforeDestroy() {
    this.$emit('update:readOnly', false)
    return this.$emit('update:isUpdate', false)
  }
}
</script>

<style lang="scss" scoped>
.property-add {
  & ::v-deep .multiselect {
    & .multiselect__select {
      margin-top: 0;
    }
    & .multiselect__tags {
      padding-top: 3px !important;
      padding-bottom: 2px;
      border: 1px solid #D8D6DE !important;
      border-radius: 6px !important;
    }
    & .multiselect__placeholder {
      padding-top: 6px;
      margin-bottom: 5px;
      margin-left: 10px;
      font-size: 12px;
    }
    & .multiselect__content-wrapper {
      z-index: 20 !important;
    }
  }
}
</style>