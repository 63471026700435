<template>
  <div>
    <div class="fd-relative fd-flex fd-items-center fd-justify-between fd-p-3" style="background-color: #E9EDEB;">
      <div class="fd-flex fd-items-center">
        <span class="fd-block fd-ml-2">Import</span>
      </div>
      <div @click="closeImportProperty" class="fd-cursor-pointer">
        <SvgLoader :name="'close'" />
      </div>
    </div>

    <div class="fd-p-4 fd-pt-6">
      <h1 class="fd-text-lg fd-font-semibold fd-text-theme-2">Download CSV Template</h1>
      <b-button @click="downloadFile" variant="primary" class="fd-w-full fd-mt-5">Download</b-button>
    </div>

    <div class="fd-p-4 fd-pt-12">
      <h1 class="fd-text-lg fd-font-semibold fd-text-theme-2">Upload CSV Template</h1>
      <div
        class="fd-border fd-border-dashed fd-border-theme-10 fd-flex fd-items-center fd-justify-center fd-py-12 fd-mt-8 fd-rounded-lg"
      >
        <file-upload
          @input="updateValue"
          :multiple="true"
          :post-action="getUrl"
          :drop="true"
          :drop-directory="true"
          :extensions="extensions"
          @input-file="inputFile"
          v-model="files"
          ref="upload"
          :name="'image'"
        >
          <div class="fd-w-11/12 fd-text-center fd-mx-auto">
            <SvgLoader :name="'downloadIcon'" class="fd-mx-auto" />
            <span class="fd-block fd-text-theme-10 fd-font-medium fd-text-lg fd-mt-4">Drop files here or click to upload</span>
            <span class="fd-block fd-text-theme-10 fd-font-normal fd-text-sm fd-mt-3">(This is just a demo dropzone. Selected files are not actually uploaded.)</span>
            <input type="file" ref="file" @change="uploadFile" style="display: none">
            <b-button variant="outline-primary" class="fd-mt-6">Select File</b-button>
          </div>
        </file-upload>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, BSidebar } from 'bootstrap-vue'
import toast from "@/mixins/toast";

export default {
  name: "ImportProperty",
  components: {BButton, BSidebar},
  mixins: [toast],
  data() {
    return {
      files: [],
      extensions: '',
    }
  },
  props: {
    postAction: {
      type: String,
      required: true
    }
  },
  computed: {
    getUrl() {
      return this.postAction
    }
  },
  methods: {
    closeImportProperty() {
      this.$root.$emit('bv::toggle::collapse', 'import-sidebar')
    },
    upload() {
      this.$refs.file.click()
    },
    uploadFile() {
      const file = this.$refs.file.files[0]
      let formData = new FormData()
      formData.append('file', file)
      this.$store.dispatch('project/uploadPropertyFile', formData)
    },
    downloadFile() {
      this.$store.dispatch('project/downloadFile', this.$route.params.id).then(response => {
        window.open(response.data.url, '_blank', 'download');
      })
    },
    updateValue(value) {
      this.files = value
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        // Add file
        newFile.blob = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file)
        }
      }

      if (newFile && oldFile) {
        // Update file

        // Start upload
        if (newFile.active !== oldFile.active) {

          // min size
          if (newFile.size >= 0 && newFile.size < 100 * 1024) {
            newFile = this.$refs.upload.update(newFile, {error: 'size'})
          }
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          this.$refs.upload.remove(newFile)
          this.makeToast()
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }

      // Automatic upload
      if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
        if (!this.$refs.upload.active) {
          this.$refs.upload.active = true
        }
      }
    },
    makeToast() {
      this.makeToast('Toast body content')
    },
  }
}
</script>

<style scoped>

</style>